
var lastScrollTop = 0;


$(document).ready(function(){

    log("hallo");

});


$(window).scroll(function () {
    
    st = $(this).scrollTop();

    d = 1;
    if (st < lastScrollTop) {
        d = 1; 
    }
    else {
       d = -1;
    }
    lastScrollTop = st;

    
    parallax('parallax1', 0.009, d)

});



function parallax(e,m,d) {

    
    //element|multiplikator|direction

    pos = $('#'+e).offset().top;

    pos_neu = pos+((pos*m)*d);
    log(pos_neu);
    $('#' + e).css('top',pos_neu+'px');
}




function log(i) {
    console.log(i);
}